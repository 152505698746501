import {
  CHIP_DEFAULT_FLOW_RATE, CHIP_DEFAULT_HEP_DONOR,
  CHIP_DEFAULT_HEP_NUM, CHIP_DEFAULT_LY_VOL, CHIP_DEFAULT_MEDIA_TYPE,
  CHIP_DEFAULT_TOTAL_VOL,
  CHIP_INPUTS,
  COCKTAIL_INPUTS,
  EXPERIMENT_INPUTS,
  SampleLocation,
  STUDY_TYPES
} from '../../../shared/constant'

const chipDefaults = {
  [CHIP_INPUTS.id]: '',
  [CHIP_INPUTS.flowRate]: CHIP_DEFAULT_FLOW_RATE,
  [CHIP_INPUTS.totalVol]: CHIP_DEFAULT_TOTAL_VOL,
  [CHIP_INPUTS.hepNum]: CHIP_DEFAULT_HEP_NUM,
  [CHIP_INPUTS.hepDonor]: CHIP_DEFAULT_HEP_DONOR,
  [CHIP_INPUTS.lyVol]: CHIP_DEFAULT_LY_VOL,
  [CHIP_INPUTS.lungVol]: '',
  [CHIP_INPUTS.rna]: '',
  [CHIP_INPUTS.dna]: '',
  [CHIP_INPUTS.mediaType]: CHIP_DEFAULT_MEDIA_TYPE
}

const chipDefaultsDDI = {
  [CHIP_INPUTS.id]: '',
  [CHIP_INPUTS.flowRate]: CHIP_DEFAULT_FLOW_RATE,
  [CHIP_INPUTS.hepNum]: CHIP_DEFAULT_HEP_NUM,
  [CHIP_INPUTS.hepDonor]: CHIP_DEFAULT_HEP_DONOR,
  [CHIP_INPUTS.lungVol]: '',
  [CHIP_INPUTS.rna]: '',
  [CHIP_INPUTS.dna]: '',
  [CHIP_INPUTS.mediaType]: CHIP_DEFAULT_MEDIA_TYPE
}

const conditionDefaults = {
  [COCKTAIL_INPUTS.sampleVolume]: '50',
  [COCKTAIL_INPUTS.timepoints]: [],
  [COCKTAIL_INPUTS.locations]: [SampleLocation.SAMPLING_CHAMBER],
  [COCKTAIL_INPUTS.technicalReplicates]: '3'
}

const intracellularDefaults = {
  selected: false,
  [COCKTAIL_INPUTS.sampleVolume]: '100',
  [COCKTAIL_INPUTS.locations]: [SampleLocation.SAMPLING_CHAMBER],
  [COCKTAIL_INPUTS.technicalReplicates]: '1'
}

const metabolismDefaults = {
  [COCKTAIL_INPUTS.timepoints]: [1, 4, 24, 48, 72],
  [COCKTAIL_INPUTS.sampleVolume]: '50',
  [COCKTAIL_INPUTS.locations]: [SampleLocation.SAMPLING_CHAMBER],
  [COCKTAIL_INPUTS.linkedChips]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '1',
  [COCKTAIL_INPUTS.intracellular]: { ...intracellularDefaults }
}

const metabolismDefaultsDDI = {
  [COCKTAIL_INPUTS.timepoints]: [1, 4, 24, 48, 72],
  [COCKTAIL_INPUTS.sampleVolume]: '50',
  [COCKTAIL_INPUTS.locations]: [SampleLocation.SAMPLING_CHAMBER],
  [COCKTAIL_INPUTS.iiLinkedChips]: [],
  [COCKTAIL_INPUTS.vehicleControllinkedChips]: [],
  [COCKTAIL_INPUTS.victimDrug]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '1'
}

const preIncubationDefaults = {
  [COCKTAIL_INPUTS.drugName]: '',
  [COCKTAIL_INPUTS.mediaVolumePerChip]: '1.8',
  [COCKTAIL_INPUTS.concentration]: '50',
  [COCKTAIL_INPUTS.treatmentStarts]: '3',
  [COCKTAIL_INPUTS.treatmentEnds]: '6',
  [COCKTAIL_INPUTS.incubationDuration]: '72',
  [COCKTAIL_INPUTS.notes]: ''
}

const victimDrugDefaults = {
  [COCKTAIL_INPUTS.preIncubationDrugName]: '',
  [COCKTAIL_INPUTS.concentration]: '50',
  [COCKTAIL_INPUTS.mediaVolumePerChip]: '',
  [COCKTAIL_INPUTS.treatmentStarts]: '6',
  [COCKTAIL_INPUTS.treatmentEnds]: '9',
  [COCKTAIL_INPUTS.incubationDuration]: '72',
  [COCKTAIL_INPUTS.technicalReplicates]: '1',
  [COCKTAIL_INPUTS.notes]: ''
}

const nsbDefaults = {
  [COCKTAIL_INPUTS.timepoints]: [1, 4, 24, 48, 72],
  [COCKTAIL_INPUTS.sampleVolume]: '50',
  [COCKTAIL_INPUTS.locations]: [SampleLocation.SAMPLING_CHAMBER],
  [COCKTAIL_INPUTS.vehicleControllinkedChips]: [],
  [COCKTAIL_INPUTS.linkedChips]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '1'
}

const nsbInhibitionDefaults = {
  [COCKTAIL_INPUTS.timepoints]: [1, 4, 24, 48, 72],
  [COCKTAIL_INPUTS.sampleVolume]: '50',
  [COCKTAIL_INPUTS.locations]: [SampleLocation.SAMPLING_CHAMBER],
  [COCKTAIL_INPUTS.vehicleControllinkedChips]: [],
  [COCKTAIL_INPUTS.linkedChips]: [],
  [COCKTAIL_INPUTS.technicalReplicates]: '1'
}

const cocktailDefaults = {
  [COCKTAIL_INPUTS.drugs]: [],
  [COCKTAIL_INPUTS.workingSolution]: {
    ...conditionDefaults,
    [COCKTAIL_INPUTS.locations]: [SampleLocation.NA],
    [COCKTAIL_INPUTS.timepoints]: [0, ...conditionDefaults[COCKTAIL_INPUTS.timepoints]]
  },
  [COCKTAIL_INPUTS.metabolismCondition]: { ...metabolismDefaults },
  [COCKTAIL_INPUTS.nsbCondition]: { ...nsbDefaults }
}

const inhibitionCocktailDefaults = {
  [COCKTAIL_INPUTS.victimDrug]: { ...victimDrugDefaults },
  [COCKTAIL_INPUTS.metabolismCondition]: { ...metabolismDefaultsDDI },
  [COCKTAIL_INPUTS.nsbCondition]: { ...nsbInhibitionDefaults }
}

const experimentDefaults = {
  [EXPERIMENT_INPUTS.id]: '',
  [EXPERIMENT_INPUTS.studyType]: STUDY_TYPES.hepaticClearance,
  [EXPERIMENT_INPUTS.isChipOnly]: false,
  [EXPERIMENT_INPUTS.chips]: [{ ...chipDefaults }],
  [EXPERIMENT_INPUTS.cocktails]: [{ ...cocktailDefaults }]
}

const inhibitionExperimentDefaults = {
  [EXPERIMENT_INPUTS.id]: '',
  [EXPERIMENT_INPUTS.studyType]: STUDY_TYPES.Inhibition,
  [EXPERIMENT_INPUTS.isChipOnly]: false,
  [EXPERIMENT_INPUTS.chips]: [{ ...chipDefaultsDDI }],
  [EXPERIMENT_INPUTS.cocktails]: [{ ...inhibitionCocktailDefaults }],
  [EXPERIMENT_INPUTS.inducerdrugs]: [{ [COCKTAIL_INPUTS.perpetrator]: { ...preIncubationDefaults } }]

}

const inducerExperimentDefaults = {
  [EXPERIMENT_INPUTS.id]: '',
  [EXPERIMENT_INPUTS.studyType]: STUDY_TYPES.Inducer,
  [EXPERIMENT_INPUTS.isChipOnly]: false,
  [EXPERIMENT_INPUTS.chips]: [{ ...chipDefaultsDDI }],
  [EXPERIMENT_INPUTS.cocktails]: [{ ...inhibitionCocktailDefaults }],
  [EXPERIMENT_INPUTS.inducerdrugs]: [{ [COCKTAIL_INPUTS.perpetrator]: { ...preIncubationDefaults } }]
}

export {
  experimentDefaults,
  inhibitionExperimentDefaults,
  inducerExperimentDefaults,
  cocktailDefaults,
  nsbDefaults,
  intracellularDefaults,
  preIncubationDefaults,
  nsbInhibitionDefaults,
  victimDrugDefaults,
  metabolismDefaultsDDI,
  conditionDefaults,
  inhibitionCocktailDefaults,
  chipDefaultsDDI
}
