import {
  CHIP_DEFAULT_FLOW_RATE, CHIP_DEFAULT_HEP_DONOR,
  CHIP_DEFAULT_HEP_NUM, CHIP_DEFAULT_LY_VOL, CHIP_DEFAULT_MEDIA_TYPE,
  CHIP_INPUTS,
  COCKTAIL_INPUTS,
  EXPERIMENT_INPUTS, INTRACELLULAR_CONDITION, METABOLISM_CONDITION, NSB_CONDITION, WORKING_SOLUTION,
  STUDY_TYPES
} from '../../../shared/constant'
import { cocktailDefaults, intracellularDefaults, nsbDefaults } from './defaultValues.js'

const populateHCFromTemplate = (template) => {
  if (!template) {
    return null
  }

  const solutionsData = template.maindata.reduce((acc, item) => {
    const solutionAttribute = 'cocktail' in item ? 'cocktail' : 'solution'
    if (!acc[item[solutionAttribute].value]) {
      acc[item[solutionAttribute].value] = []
    }
    acc[item[solutionAttribute].value].push(item)
    return acc
  }, {})

  const solutions = Object.keys(solutionsData)

  const experiment = {
    [EXPERIMENT_INPUTS.studyType]: STUDY_TYPES.hepaticClearance,
    [EXPERIMENT_INPUTS.id]: template.expinfo.infos.experiment_id,
    [EXPERIMENT_INPUTS.chips]: template.expinfo.chips.map(templateChip => templateToChip(templateChip)),
    [EXPERIMENT_INPUTS.cocktails]: solutions.length > 0 ? solutions.map(key => templateToCocktail(solutionsData[key])) : [{ ...cocktailDefaults }]
  }

  const isChipOnly = experiment.cocktails.some(e => e.nsbCondition?.[COCKTAIL_INPUTS.linkedChips]?.length > 0)

  return { experiment, isChipOnly }
}

const templateToChip = (chip) => {
  return {
    [CHIP_INPUTS.id]: chip.chip_id.value || '',
    [CHIP_INPUTS.flowRate]: chip.flow_rate.value ? `${chip.flow_rate.value} ${chip.flow_rate.unit}` : CHIP_DEFAULT_FLOW_RATE,
    [CHIP_INPUTS.totalVol]: chip.total_volume.value ? `${chip.total_volume.value} ${chip.total_volume.unit}` : CHIP_DEFAULT_FLOW_RATE,
    [CHIP_INPUTS.hepNum]: chip.hepatocyte_number.value ? `${chip.hepatocyte_number.value}` : CHIP_DEFAULT_HEP_NUM,
    [CHIP_INPUTS.hepDonor]: chip.hepatocyte_donor.value ? `${chip.hepatocyte_donor.value}` : CHIP_DEFAULT_HEP_DONOR,
    [CHIP_INPUTS.lyVol]: chip.lysis_volume.value ? `${chip.lysis_volume.value} ${chip.lysis_volume.unit}` : CHIP_DEFAULT_LY_VOL,
    [CHIP_INPUTS.lungVol]: chip.final_lung_volume.value ? `${chip.final_lung_volume.value} ${chip.final_lung_volume.unit}` : '',
    [CHIP_INPUTS.rna]: chip.rna.value ? `${chip?.rna.value} ${chip.rna.unit}` : '',
    [CHIP_INPUTS.dna]: chip.dna.value ? `${chip.dna.value} ${chip.dna.unit}` : '',
    [CHIP_INPUTS.mediaType]: chip.media_type ? `${chip.media_type.value} ${chip.media_type.unit}` : CHIP_DEFAULT_MEDIA_TYPE,
    [CHIP_INPUTS.comment]: chip.comments.value || ''
  }
}

const templateToCocktail = (solution) => {
  const filterByCondition = (conditionValue) => solution.filter(e => e.condition.value.toLowerCase() === conditionValue.toLowerCase())

  const metabolismConditionData = filterByCondition(METABOLISM_CONDITION)
  const nsbConditionData = filterByCondition(NSB_CONDITION)

  const workingCondition = extractDetails(filterByCondition(WORKING_SOLUTION))
  const metabolismCondition = extractDetails(metabolismConditionData)
  const intracellularConditions = extractDetails(filterByCondition(INTRACELLULAR_CONDITION))
  const nsbConditions = extractDetails(nsbConditionData)

  return {
    [COCKTAIL_INPUTS.drugs]: [...new Set(solution.map(e => e.drug.value))],
    [COCKTAIL_INPUTS.workingSolution]: workingCondition,
    [COCKTAIL_INPUTS.metabolismCondition]: {
      ...metabolismCondition,
      ...extractLinkedChips(metabolismConditionData),
      [COCKTAIL_INPUTS.intracellular]: intracellularConditions || { ...intracellularDefaults }
    },
    [COCKTAIL_INPUTS.nsbCondition]: nsbConditions
      ? {
        ...nsbConditions,
        ...extractLinkedChips(nsbConditionData)
      }
      : { ...nsbDefaults }
  }
}

const extractDetails = (conditions) => {
  if (conditions.length === 0) { return null }

  return {
    [COCKTAIL_INPUTS.timepoints]: [...new Set(conditions.map(e => e.sample_time.value))],
    [COCKTAIL_INPUTS.sampleVolume]: `${conditions[0].sample_volume.value} ${conditions[0].sample_volume._meta.raw_unit}`,
    [COCKTAIL_INPUTS.technicalReplicates]: Math.max(...conditions.map(e => e.technical_replicate.value)),
    [COCKTAIL_INPUTS.locations]: [...new Set(conditions.map(e => e.sample_location.value))]
  }
}

const extractLinkedChips = (conditions) => {
  if (conditions.length === 0) { return null }

  return {
    [COCKTAIL_INPUTS.linkedChips]: [...new Set(conditions.map(e => e.chip_id.value))]
  }
}

export {
  populateHCFromTemplate
}
