import { utils } from 'xlsx'
import { cartesian, groupBy, range } from './common'

const hiddenCols = {
  experimentSheet: ['P', 'Q']
}

const isInducerStudy = function (studyType = '') {
  return studyType.toLowerCase().includes('induction')
}

// Resolve/link all element as if we would have a real/strong
// model with bidirectionnal links
const extractCocktailsChip = function (content) {
  const cocktails = [...content.cocktails]
  for (const cocktail of cocktails) {
    const metabolism = cocktail.metabolismCondition
    metabolism.chips = content.chips.filter(x => metabolism['Control LTC(s) (biological replicates)'].includes(x['Chip ID']))
    cocktail.chips = [...metabolism.chips] // link to all used chips for this cocktail
    const nsb = cocktail.nsbCondition
    if (nsb && nsb['LTC(s) (biological replicates)']?.length > 0) {
      nsb.chips = content.chips.filter(x => nsb['Linked Chips (biological replicates)'].includes(x['Chip ID']))
      cocktail.chips.push(...nsb.chips.filter(x => !cocktail.chips.includes(x['Chip ID'])))
    }
    content.chips.filter(x => cocktail.chips.map(c => c['Chip ID']).includes(x['Chip ID'])).forEach(x => {
      if (!('cocktails' in x)) {
        x.cocktails = []
      }
      if (!x.cocktails.filter(x => x.name).includes(cocktail.name)) {
        x.cocktails.push(cocktail)
      }
    })
  }
  return cocktails
}

const generateTubeData = function (victim, victimID, technicalReplicates, workingSolution, volume) {
  return range(technicalReplicates.length, 1).map(tR => {
    return {
      'Sample ID': `C0P0V${victimID}T0R${tR}S${workingSolution}`,
      'Chip ID': 'Tube',
      'Perpetrator Drug': 'NA',
      'Victim Drug': victim,
      'Sample time (h)': 0,
      Condition: 'Working Solution',
      'Technical Replicate': tR,
      'Biological Replicate': 0,
      'Working Solution': workingSolution,
      'Sample Volume (uL)': volume,
      'Sample Location': 'N/A',
      Data: '',
      'Reject (mark "x")': '',
      Comments: ''
    }
  })
}

const generateDDIData = function (cocktails, chipOnly, descending, haveNSB, studyType) {
  const ddiData = []
  let workingSolution = 1
  cocktails.forEach(mix => {
    const condition = mix.metabolismCondition
    const victimChips = condition['Control LTC(s) (biological replicates)']
    const perpetratorDrugs = [...mix.victimDrug['Pre Incubation Drug Name'], 'NA']
    const victimDrugs = mix.victimDrug['Drug Name']
    const technicalReplicates = range(parseInt(condition['Technical Replicates']), 1)
    const timepoints = [...condition.Timepoints].map(t => parseInt(t)).sort((a, b) => descending ? b - a : a - b)
    const combination = cartesian(perpetratorDrugs, victimDrugs, technicalReplicates, timepoints)
    const sortedCombination = groupBy(combination, (x) => `${x[0]}+${x[1]}`) // We create the key to group the entries

    for (const victimDrug of victimDrugs) {
      const volume = parseFloat(mix.victimDrug.Concentration)
      const victimTechnicalReplicates = range(parseInt(mix.victimDrug['Technical Replicates']), 1)
      const tubeEntries = generateTubeData(victimDrug, victimDrugs.indexOf(victimDrug) + 1, victimTechnicalReplicates, workingSolution, volume)
      if (!descending) {
        ddiData.push(...tubeEntries)
      }

      // First entries for the Control condition
      // (even if here they are mixed in the same metabolism condition from HC)
      const controlKey = `NA+${victimDrug}`
      const controlEntries = cartesian(victimChips, sortedCombination.get(controlKey))
      for (const [chipID, [perpetrator, victim, tR, time]] of controlEntries) {
        const bR = victimChips.indexOf(chipID) + 1
        const row = {
          'Sample ID': `C${bR}P0V${victimDrugs.indexOf(victim) + 1}T${condition.Timepoints.indexOf(time)}R${tR}S${workingSolution}`,
          'Chip ID': chipID,
          'Perpetrator Drug': perpetrator,
          'Victim Drug': victim,
          'Sample time (h)': time,
          Condition: 'Control',
          'Technical Replicate': tR,
          'Biological Replicate': bR,
          'Working Solution': workingSolution,
          'Sample Volume (uL)': volume,
          'Sample Location': condition.Locations[0],
          Data: '',
          'Reject (mark "x")': '',
          Comments: ''
        }
        ddiData.push(row)
      }

      if (descending) {
        ddiData.push(...tubeEntries)
      }

      // Then entries for the Induced condition
      const mixChipIDs = condition['LTC(s) (biological replicates)']
      const mixKey = `${perpetratorDrugs[0]}+${victimDrug}`
      // const perpetratorConfig = perpetrators.find(p => p['Drug Name'] === perpetratorDrugs[0])
      // const perpetratorVolume = parseFloat(perpetratorConfig.Concentration)
      const inducedEntries = cartesian(mixChipIDs, sortedCombination.get(mixKey))
      for (const [chipID, [perpetrator, victim, tR, time]] of inducedEntries) {
        const bR = mixChipIDs.indexOf(chipID) + 1
        const row = {
          'Sample ID': `C${bR}P1V${victimDrugs.indexOf(victim) + 1}T${condition.Timepoints.indexOf(time)}R${tR}S${workingSolution}`,
          'Chip ID': chipID,
          'Perpetrator Drug': perpetrator,
          'Victim Drug': victim,
          'Sample time (h)': time,
          Condition: isInducerStudy(studyType) ? 'Induced' : 'Inhibited',
          'Technical Replicate': tR,
          'Biological Replicate': bR,
          'Working Solution': workingSolution,
          'Sample Volume (uL)': volume,
          'Sample Location': condition.Locations[0],
          Data: '',
          'Reject (mark "x")': '',
          Comments: ''
        }
        ddiData.push(row)
      }
    }
    // Then generates the entries for the NSB condition
    if (chipOnly) {
      const nsbCondition = mix.nsbCondition
      const nsbChipIDs = nsbCondition['Linked Chips (biological replicates)']
      const nsbTechnicalReplicates = range(parseInt(nsbCondition['Technical Replicates']), 1)
      const nsbTimepoints = [...nsbCondition.Timepoints].sort((a, b) => descending ? b - a : a - b)
      const nsbEntries = cartesian(nsbChipIDs, ['NA'], victimDrugs, nsbTechnicalReplicates, nsbTimepoints)
      for (const [chipID, perpetrator, victim, tR, time] of nsbEntries) {
        const bR = nsbChipIDs.indexOf(chipID) + 1
        const row = {
          'Sample ID': `C${bR}P1V${victimDrugs.indexOf(victim) + 1}T${nsbCondition.Timepoints.indexOf(time)}R${tR}S${workingSolution}N`,
          'Chip ID': chipID,
          'Perpetrator Drug': perpetrator,
          'Victim Drug': victim,
          'Sample time (h)': time,
          Condition: 'NSB',
          'Technical Replicate': tR,
          'Biological Replicate': bR,
          'Working Solution': workingSolution,
          'Sample Volume (uL)': parseFloat(nsbCondition['Sample Volume']),
          'Sample Location': nsbCondition.Locations[0],
          Data: '',
          'Reject (mark "x")': '',
          Comments: ''
        }
        ddiData.push(row)
      }
    }
    workingSolution++
  })
  return ddiData
}

// Generates the chip data, linking the cocktails
const generateChipData = function (content) {
  const cocktails = extractCocktailsChip(content);
  const startRow = 5; // Currently fixed column (can change later)
  const ref = {
    c: 1,
    r: Math.max(3 + 1, startRow) // Minimum of three headers: "maturation days", "pre-induction", "victim+inducer"
  };

  const cocktailHash = (cocktail) => {
    const { Name, victimDrug, metabolismCondition, nsbCondition } = cocktail;
    let clonedMetabolismCondition = { ...metabolismCondition };
    delete clonedMetabolismCondition.chips;
    let clonedNsbCondition = { ...nsbCondition };
    delete clonedNsbCondition.chips;
    return `${Name || ''}-${JSON.stringify(victimDrug)}-${JSON.stringify(clonedMetabolismCondition)}-${nsbCondition ? JSON.stringify(clonedNsbCondition) : ""}`;
  };

  const chipCocktailHashList = [];
  content.cocktails.forEach((cocktail) => {
    const hashKey = cocktailHash(cocktail);
    if (!chipCocktailHashList.includes(hashKey)) {
      chipCocktailHashList.push(hashKey);
    }
  });

  const processedChips = new Set(); // Track processed chips
  const rows = [];

  const findCocktailIndexByChipId = (cocktails, chipIds) => {
    for (let i = 0; i < cocktails.length; i++) {
      const metabolismCondition = cocktails[i].metabolismCondition;
  
      // Check Control LTC(s)
      const controlLTCs = metabolismCondition["Control LTC(s) (biological replicates)"];
      if (controlLTCs && controlLTCs.some(chip => chipIds.includes(chip))) {
        return i; // Return the index if found in Control LTC(s)
      }
  
      // Check LTC(s)
      const ltcs = metabolismCondition["LTC(s) (biological replicates)"];
      if (ltcs && ltcs.some(chip => chipIds.includes(chip))) {
        return i; // Return the index if found in LTC(s)
      }
    }
  
    return -1; // Return -1 if not found
  };
  
  const processChipGroup = (chipIDs, groupLabel) => {
    for (const chipID of chipIDs) {
      if (processedChips.has(chipID)) {continue;} // Skip already processed chips
  
      const chip = content.chips.find(c => c['Chip ID'] === chipID);
      if (!chip) {continue;}

      const cocktailIndex = findCocktailIndexByChipId(content.cocktails, chipIDs);
  
      rows.push({
        'Chip ID': chipID,
        'Flow Rate (mL/h)': parseFloat((`${chip['Flow Rate']}` || '').replace(/[^.\d]+/, '')) || '',
        'Total Volume (mL)': parseFloat((`${chip['Total Volume']}` || '').replace(/[^.\d]+/, '')) || '',
      
        // Exclude Hepatocyte Number and Donor for NSB chips
        'Hepatocyte Number': groupLabel === 'NSB' ? '' : (parseFloat(`${chip['Hepatocyte Number']}`) || ''),
        'Hepatocyte Donor': groupLabel === 'NSB' ? '' : (chip['Hepatocyte Donor'] || ''),
      
        // Remove Inducer Information for Control Chips
        'Inducer Conc (uM)': groupLabel === 'Control' ? 'NA' : (cocktailIndex !== -1 ? content.inducerdrugs[cocktailIndex].perpetrator.Concentration : ''),
        'Inducer Start Date': groupLabel === 'Control' ? 'NA' : (cocktailIndex !== -1 ? content.inducerdrugs[cocktailIndex].perpetrator['Treatment Start Day'] : ''),
        'Inducer Study Duration (hrs)': groupLabel === 'Control' ? 'NA' : (cocktailIndex !== -1 ? content.inducerdrugs[cocktailIndex].perpetrator.Duration : ''),
      
        // Victim Information
        'Victim Conc (uM)': cocktailIndex !== -1 ? content.cocktails[cocktailIndex].victimDrug.Concentration : '',
        'Victim Start Date': cocktailIndex !== -1 ? content.cocktails[cocktailIndex].victimDrug['Treatment Start Day'] : '',
        'Victim Study Duration (hrs)': cocktailIndex !== -1 ? content.cocktails[cocktailIndex].victimDrug.Duration : '',
      
        // NSB Chips: Include all drugs as victim drugs
        'Victim Drug': groupLabel === 'NSB' 
          ? content.cocktails.map(cocktail => cocktail.victimDrug['Drug Name']).join(', ') 
          : (cocktailIndex !== -1 ? (content.cocktails[cocktailIndex].victimDrug['Drug Name'] || []).join(', ') : ''),
      
        // Media Type
        'Media Type': chip['Media Type'] ? chip['Media Type'] : '',
      
        // Perpetrator Drug
        'Perpetrator Drug': groupLabel === 'Control' ? 'NA' : (cocktailIndex !== -1 ? (content.cocktails[cocktailIndex].victimDrug['Pre Incubation Drug Name'] || []).join(', ') : ''),
      
        // RNA and DNA
        'RNA (ng)': parseFloat((chip.RNA || '').replace(/[^.\d]+/, '')) || '',
        'DNA (ng)': parseFloat((chip.DNA || '').replace(/[^.\d]+/, '')) || '',
      
        // Set Condition to "Linked" for NSB chips
        'Condition': groupLabel === 'NSB' ? 'Linked' : (groupLabel || ''),
      
        // Comments placeholder
        'Comments': '' // Placeholder for comments
      });
  
      processedChips.add(chipID); // Mark chip as processed
    }
  };

  for (const cocktail of cocktails) {
    const condition = cocktail.metabolismCondition;

    // Process Control LTC(s)
    processChipGroup(condition['Control LTC(s) (biological replicates)'], 'Control');

    // Process LTC(s)
    processChipGroup(condition['LTC(s) (biological replicates)'], 'Induced');

    // Process Linked Chips
    if (cocktail.nsbCondition) {
      const nsbCondition = cocktail.nsbCondition;
      processChipGroup(nsbCondition['Linked Chips (biological replicates)'], 'NSB');
    }
  }

  // Process orphaned chips (those that haven't been processed yet)
  const orphanedChips = content.chips
    .filter(chip => !processedChips.has(chip['Chip ID']))
    .map(chip => chip['Chip ID']);

  processChipGroup(orphanedChips, 'Not-Linked');

  // Internal function to add comments
  const addComments = (chipID) => {
    let cocktailNames = [];
  
    // Iterate over all cocktails to check for matches
    content.cocktails.forEach((cocktail, index) => {
      const condition = cocktail.metabolismCondition;
      const nsbCondition = cocktail.nsbCondition;
  
      // Check if the chipID is present in Control LTC(s) or LTC(s)
      if (condition['Control LTC(s) (biological replicates)'].includes(chipID) ||
          condition['LTC(s) (biological replicates)'].includes(chipID)) {
  
        // Add the cocktail name or fallback to Solution name
        const solutionName = cocktail.Name || `Solution ${index + 1}`;
        cocktailNames.push(solutionName);
      }
  
      // Check if the chipID is present in nsbCondition Linked Chips
      if (nsbCondition['Linked Chips (biological replicates)'].includes(chipID) ||
      nsbCondition['LTC(s) (biological replicates)'].includes(chipID)) {
  
        // Add the cocktail name or fallback to Solution name
        const solutionName = cocktail.Name || `Solution ${index + 1}`;
        cocktailNames.push(solutionName);
      }
    });
  
    // Return the cocktail names joined with " & "
    return cocktailNames.sort().join(' & ');
  };

  // Apply the comments to the rows
  rows.forEach(row => {
    const chipId = row['Chip ID'];
    const comment = addComments(chipId);
    row['Comments'] = comment;

    if (comment.startsWith("Solution")) {
        const solutionIndices = comment.match(/\d+/g).map(n => parseInt(n) - 1); // Extract all solution numbers
        const drugNames = solutionIndices.flatMap(index => content.cocktails[index].victimDrug['Drug Name']);
        row['Victim Drug'] = drugNames.join(", ");
    } else {
        const cocktail = content.cocktails.find(c => c.name === comment);
        if (cocktail) {
            row['Victim Drug'] = cocktail.victimDrug['Drug Name'].join(", ");
        }
    }
  });


  // Order rows by 'Chip ID'
  rows.sort((a, b) => a['Chip ID'].localeCompare(b['Chip ID']));

  const perpetrators = content.inducerdrugs.map(p => p.perpetrator);
  return { rows, cocktails, ref, perpetrators, chipOnly: content['Chip Only Studies'] };
};



export const generateDDIDataSheetDDI = function (cocktails, chipOnly, descending, haveNSB, studyType) {
  const jsondata = generateDDIData(cocktails, chipOnly, descending, haveNSB, studyType)
  return utils.json_to_sheet(jsondata)
}

export const generateChipDataSheetDDI = function (content) {
  const { rows, cocktails, ref, perpetrators, chipOnly } = generateChipData(content)

  const ws = utils.json_to_sheet([], { origin: 'A1' })
  utils.sheet_add_json(ws, rows, { origin: ref })

  ws.A1 = { t: 's', v: 'Experiment Type' }
  ws.B1 = { t: 's', v: content['Study Type'] }
  ws.A2 = { t: 's', v: 'Experiment ID' }
  ws.B2 = { t: 's', v: content['Experiment ID'] }
  ws.A3 = { t: 's', v: 'MPS Type' }
  ws.B3 = { t: 's', v: 'Javelin-LTC' }

  ws.D1 = { t: 's', v: 'Maturation Days' }
  ws.E1 = { t: 's', v: content['Experiment Type'] }
  ws.D2 = { t: 's', v: 'Pre-Induction Comments' }

  ws.E2 = { t: 's', v: content.inducerdrugs[0]?.perpetrator['Notes for additional dosing information'] }
  ws.D3 = { t: 's', v: `${isInducerStudy(content['Study Type']) ? 'Induction' : 'Inhibitor'}+Victim Comments` }
  ws.E3 = { t: 's', v: content.cocktails[0]?.victimDrug['Notes for additional dosing information'] }

  // Manually adding cell for chip details (If needed, can be skipped)
  ws[utils.encode_cell({ c: ref.c - 1, r: ref.r })] = { t: 's', v: 'Chip Details' }

  cocktails.forEach((cocktail, i) => {
    const cocktailName = cocktail.Name || `Solution ${i + 1}`
    const drugsList = `(${cocktail.victimDrug['Drug Name']}) + ${cocktail.victimDrug['Pre Incubation Drug Name']}`
    ws[`G${i + 1}`] = { t: 's', v: `${cocktailName} = ${drugsList}` }
  })

  // Hide columns if necessary based on your layout requirements
  ws['!cols'] = []
  for (const col of hiddenCols.experimentSheet) {
    const colIndex = utils.decode_col(col)
    ws['!cols'][colIndex] = { hidden: true }
  }

  return { ws, cocktails, perpetrators, chipOnly }
}

export const generateDrugPropertiesSheetDDI = (cocktails) => {
  const drugs = cocktails.flatMap(c => c.victimDrug['Drug Name'])
  const drugEntries = []

  for (const [idx, drug] of drugs.entries()) {
    drugEntries.push({
      Drug: drug,
      'fu,media': 1,
      'fu,p': 1,
      'R,bp': 1,
      'fu,b': {
        t: 'n', // Type: number
        v: '', // Value: empty
        f: `C${idx + 2} / D${idx + 2}`
      }
    })
  }

  const ws = utils.json_to_sheet(drugEntries)

  ws['!cols'] = [{ wpx: 200 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }]

  return ws
}
