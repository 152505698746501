import React, { useState } from 'react';
import { Box } from "@mui/material";
import { COCKTAIL_INPUTS, SampleLocation } from "../../../../shared/constant";
import vars from "../../../../styles/variables";
import DrugSolutions from "./DrugSolutions";
import ChipWithTissue from "./ChipWithTissue";
import ChipOnly from "./ChipOnly";

const { inputDisableBorder, labelColor } = vars;
const KEYBOARD_KEY = {
  comma: ',',
  space: ' ',
  enter: 'Enter'
};

const ACCEPTED_KEYS = [
  KEYBOARD_KEY.enter,
  KEYBOARD_KEY.space,
  KEYBOARD_KEY.comma
];

const classes = {
  root: {
    borderLeft: `0.25rem solid ${inputDisableBorder}`,
    paddingLeft: '1.5rem',
  },
  muiChipInput: {
    '& .MuiSvgIcon-root': {
      background: `url(../../../assets/svg/close.svg) no-repeat center`,
      fontSize: '2rem',
      '& path': {
        display: 'none',
      }
    }
  },
  label: {
    color: labelColor
  },
};

const HepaticClearanceDetail = (props) => {
  const [inputValues, setInputValues] = useState({});
  const { cocktail, cocktailValueChange, detailKey, heading, tabValue, chips, tab } = props;
  const [addIntracellular, setAddIntracellular] = useState(cocktail[detailKey][COCKTAIL_INPUTS.intracellular]?.selected);
  const handleChangeDrugs = (value) => {
    cocktailValueChange({
      ...cocktail,
      [COCKTAIL_INPUTS.drugs]: value
    });
    setInputValues({ ...inputValues, [COCKTAIL_INPUTS.drugs]: '' });
  };
  
  const handleInputChange = (event, key) => {
    setInputValues({ ...inputValues, [key]: event.target.value });
  };
  
  const handleChange = (value, key) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [key]: value
      }
    });
    setInputValues({ ...inputValues, [key]: '' });
  };
  
  const handleDelete = (chipIndex, cocktail, detailKey, inputKey) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [inputKey]: cocktail[detailKey][inputKey].filter((_, index) => index !== chipIndex)
      }
    });
  };
  
  const handleChangeIntracellular = (value, key) => {
    setAddIntracellular(value);
    const newIntracellular = { ...cocktail[detailKey][COCKTAIL_INPUTS.intracellular], [key]: value };
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [COCKTAIL_INPUTS.intracellular]: newIntracellular
      }
    });
  };
  
  const handleDeleteIntracellular = (chipIndex, cocktail, detailKey, inputKey) => {
    cocktailValueChange({
      ...cocktail,
      [detailKey]: {
        ...cocktail[detailKey],
        [COCKTAIL_INPUTS.intracellular]: {
          ...cocktail[detailKey][COCKTAIL_INPUTS.intracellular],
          [COCKTAIL_INPUTS[inputKey]]: cocktail[detailKey][COCKTAIL_INPUTS.intracellular][COCKTAIL_INPUTS[inputKey]].filter((_, index) => index !== chipIndex)
        }
      }
    });
  };
  
  const defaultLocations = Object.values(SampleLocation);

  const renderContent = () => {
    switch (tab) {
      case "Drug Solution":
        return <DrugSolutions
          ACCEPTED_KEYS={ACCEPTED_KEYS}
          classes={classes}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          defaultLocations={defaultLocations}
          handleChangeDrugs={handleChangeDrugs}
          inputValues={inputValues}
          tabValue={tabValue}
        />;
      case "Chip with Tissue":
        return <ChipWithTissue
          ACCEPTED_KEYS={ACCEPTED_KEYS}
          classes={classes}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          defaultLocations={defaultLocations}
          inputValues={inputValues}
          tabValue={tabValue}
          chips={chips}
          handleChangeIntracellular={handleChangeIntracellular}
          addIntracellular={addIntracellular}
          handleDeleteIntracellular={handleDeleteIntracellular}
        />;
      case "Chip only":
        return <ChipOnly
          ACCEPTED_KEYS={ACCEPTED_KEYS}
          classes={classes}
          cocktail={cocktail}
          detailKey={detailKey}
          heading={heading}
          handleChange={handleChange}
          handleInputChange={handleInputChange}
          handleDelete={handleDelete}
          defaultLocations={defaultLocations}
          inputValues={inputValues}
          tabValue={tabValue}
          chips={chips}
        />;
      default:
        return null;
    }
  };
  
  return (
    <Box>
      {renderContent()}
    </Box>
  );
};

export default HepaticClearanceDetail;
