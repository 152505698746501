import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import vars from '../../../styles/variables';

interface IInput {
  value?: string,
  onChange?: any,
  onBlur?: any,
  label?: string,
  className?: string,
  mb?: number,
  multiline?: boolean,
  rows?: number,
  focus?: boolean,
}

const { labelColor } = vars;

const Input: React.FC<IInput> = ({ value, onChange, label, className, mb, multiline, rows, focus, onBlur }) => {
  const classes = {
    label: {
      color: labelColor
    },
  };
  const textFieldRef = React.useRef(null);
  React.useEffect(() => {
    if(focus) {
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    }
  }, [focus])
  return (
    <Box mb={mb} className={className}>
      <Typography sx={classes.label} variant='body2'>{label}</Typography>
      <TextField
        multiline={multiline}
        fullWidth
        rows={rows}
        variant="outlined"
        placeholder={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        inputRef={textFieldRef}
        onBlur={onBlur}
      />
    </Box>
  )
};

export default Input;